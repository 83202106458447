import { APP_NSEC_SIZE } from '@/utils/consts'

const SIZE_VALUE = {
  [APP_NSEC_SIZE.BIG]: 70,
  [APP_NSEC_SIZE.LARGE]: 56,
  [APP_NSEC_SIZE.MEDIUM]: 48,
  [APP_NSEC_SIZE.SMALL]: 36,
  [APP_NSEC_SIZE.EXTRA_SMALL]: 30,
}

export const APP_SIZE_VALUE = {
  [APP_NSEC_SIZE.BIG]: {
    height: SIZE_VALUE[APP_NSEC_SIZE.BIG],
    minWidth: SIZE_VALUE[APP_NSEC_SIZE.BIG],
    maxWidth: SIZE_VALUE[APP_NSEC_SIZE.BIG],
  },
  [APP_NSEC_SIZE.LARGE]: {
    height: SIZE_VALUE[APP_NSEC_SIZE.LARGE],
    minWidth: SIZE_VALUE[APP_NSEC_SIZE.LARGE],
    maxWidth: SIZE_VALUE[APP_NSEC_SIZE.LARGE],
  },
  [APP_NSEC_SIZE.MEDIUM]: {
    height: SIZE_VALUE[APP_NSEC_SIZE.MEDIUM],
    minWidth: SIZE_VALUE[APP_NSEC_SIZE.MEDIUM],
    maxWidth: SIZE_VALUE[APP_NSEC_SIZE.MEDIUM],
  },
  [APP_NSEC_SIZE.SMALL]: {
    height: SIZE_VALUE[APP_NSEC_SIZE.SMALL],
    minWidth: SIZE_VALUE[APP_NSEC_SIZE.SMALL],
    maxWidth: SIZE_VALUE[APP_NSEC_SIZE.SMALL],
  },
  [APP_NSEC_SIZE.EXTRA_SMALL]: {
    height: SIZE_VALUE[APP_NSEC_SIZE.EXTRA_SMALL],
    minWidth: SIZE_VALUE[APP_NSEC_SIZE.EXTRA_SMALL],
    maxWidth: SIZE_VALUE[APP_NSEC_SIZE.EXTRA_SMALL],
  },
}

const FONT_SIZE_VALUE = {
  [APP_NSEC_SIZE.BIG]: 24,
  [APP_NSEC_SIZE.LARGE]: 20,
  [APP_NSEC_SIZE.MEDIUM]: 16,
  [APP_NSEC_SIZE.SMALL]: 12,
  [APP_NSEC_SIZE.EXTRA_SMALL]: 10,
}

export const APP_NAME_FONT_SIZE_VALUE = {
  [APP_NSEC_SIZE.LARGE]: FONT_SIZE_VALUE[APP_NSEC_SIZE.LARGE],
  [APP_NSEC_SIZE.BIG]: FONT_SIZE_VALUE[APP_NSEC_SIZE.BIG],
  [APP_NSEC_SIZE.MEDIUM]: FONT_SIZE_VALUE[APP_NSEC_SIZE.MEDIUM],
  [APP_NSEC_SIZE.SMALL]: FONT_SIZE_VALUE[APP_NSEC_SIZE.SMALL],
  [APP_NSEC_SIZE.EXTRA_SMALL]: FONT_SIZE_VALUE[APP_NSEC_SIZE.EXTRA_SMALL],
}
